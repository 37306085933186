var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('div',{attrs:{"id":"data-list"}},[_c('data-filter',{on:{"filter-data":_vm.filterData},model:{value:(_vm.isFilterDataSidebarActive),callback:function ($$v) {_vm.isFilterDataSidebarActive=$$v},expression:"isFilterDataSidebarActive"}}),_c('v-card',{class:_vm.$vuetify.breakpoint.mdAndDown ? '' : ' rounded-t-lg',attrs:{"tile":"","flat":_vm.$vuetify.breakpoint.mdAndDown}},[_c('v-toolbar',{attrs:{"color":_vm.$vuetify.breakpoint.mdAndDown ? '#3b355a' : 'transparent',"flat":"","height":_vm.$vuetify.breakpoint.smAndDown ? 57 : 87}},[_c('v-toolbar-title',{staticClass:"text-h6 font-weight-medium"},[_c('v-icon',{attrs:{"large":"","left":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiCrosshairs)+" ")]),_vm._v(" Loglar ")],1)],1),_c('v-data-table',{attrs:{"headers":_vm.tableColumns,"items":_vm.dataListTable,"options":_vm.options,"items-per-page":15,"fixed-header":"","height":_vm.dataTableHeight,"server-items-length":_vm.totalDataListTable,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"d-flex align-center justify-center text-caption text--disabled",attrs:{"link":"","label":""}},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"item.kim",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"d-flex align-center justify-center text-caption",staticStyle:{"letter-spacing":"1px !important"},attrs:{"label":"","outlined":"","color":"primary"}},[_vm._v(" "+_vm._s(item.kim)+" ")])]}},{key:"item.tarih",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text--secondary text-caption"},[_vm._v(" "+_vm._s(item.tarih.substring(0, 16)))])]}},{key:"item.aciklama",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"secondary","depressed":"","small":"","block":""},on:{"click":function($event){return _vm.aciklamaFn(item.aciklama, item.baslik)}}},[_vm._v(" Açıklama ")])]}}],null,true),model:{value:(_vm.selectedRows),callback:function ($$v) {_vm.selectedRows=$$v},expression:"selectedRows"}})],1),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.isDialogAciklama),callback:function ($$v) {_vm.isDialogAciklama=$$v},expression:"isDialogAciklama"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.dialogBaslik)+" ")]),_c('v-divider'),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.dialogAciklama)+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.isDialogAciklama = false}}},[_vm._v(" Kapat ")])],1)],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }